import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import FooterLogin from "src/layouts/compact/FooterLogin";
import { openVenueFarAwayModal, openVenueLocationModal } from 'src/redux/slices/VenueLocationModalSlice';
import api from "src/utils/axios";
import { fetchImage } from "src/utils/fetchImage";
import useGeolocation from "src/utils/geolocation";
import { useSnackbar } from "../../../components/snackbar";
import CarInformationModal from "../CarInformation/CarInformationModal";
import CartItemModal from "../cartModal/CartItemModal";
import VenueHeader from "../VenueHeader";
import VenueCover from "./VenueCover";
import VenueProfileMenu from "./VenueProfileMenu";
import { getVenueListWithLocationAndType } from "src/redux/slices/venueSlice";
import { closeVenueLocationModal, closeVenueFarAwayModal } from 'src/redux/slices/VenueLocationModalSlice';

const VenueProfiles = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.login);
  const { venues } = useSelector((state) => state.venues);
  const { cardItems } = useSelector((state) => state.cardSlice);

  const { venueId } = useParams();
  const {  state } = useLocation();
  const [openCart, setOpenCart] = useState(false);
  const [openCarInformationModal, setOpenCarInformationModal] = useState(false);
  const { triggerLocation } = useGeolocation();
  const dispatch = useDispatch();

  const { venueIdToBlock } = useSelector((state) => state.venueLocationModal); 
  const handleOpenModal = () => dispatch(openVenueLocationModal()); 
  const handleOpenVenueFarAwayModal = (venueId) => dispatch(openVenueFarAwayModal(venueId)); 


  const handleClose = () => {
    setOpenCart(false);
  };

  const handleCloseCarInformationModal = () => {
    setOpenCarInformationModal(false);
  };
 
  const [menuData, setMenuData] = useState([]);
  const [modeOfVenue, setModeOfVenue] = useState({
    isPickUp: false,
    isDineIn: false,
  });
  const [venuesCoverData, setVenuesCoverData] = useState({
    detail: "",
    image: "",
    distance: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchMenuData = useCallback(async () => {
    const errorMessage = state?.errorMessage;
    const openModal = state?.openModal;
    
    if(errorMessage){
      // enqueueSnackbar(errorMessage,{variant:"error"});
      setOpenCart(true)
     }
    if(openModal){
      setOpenCart(true)
    }
    try {
      setLoading(true);
      const venue = venues.find((venue) => venue.id === venueId);
      const venueResponse = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${venueId}`
      );
      const res = await fetchImage(venueResponse?.data?.photoURL);
      setVenuesCoverData({
        detail: venueResponse?.data,
        image: res,
        distance: venue?.distance,
      });
      const menuResponse = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/menu/?levelId=${venueResponse?.data?.levels[0]?.id}&venueId=${venueId}`
      );
      setModeOfVenue({
        isPickUp: venueResponse?.data?.configurations?.isPickUp,
        isDineIn: venueResponse?.data?.configurations?.isDineIn,
      });
      setMenuData(menuResponse.data.results);

      let orderType = localStorage.getItem("mode");
      let qrScannedVenueId = localStorage.getItem("venueId");
      if((orderType == 'QrDineIn' || orderType == 'QrPickUp') && (venueId == qrScannedVenueId) ){
       getLocation(orderType, venueResponse?.data )
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [venueId, venues]);

  const getLocation = useCallback(async (orderType, venueData) => {
    try {
      const location =  await triggerLocation(handleOpenModal)
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/venues/${venueId}/getLiveDistanceFromVenue/`,
         {
          params: {
            location: location,
            
          }
        }
      );

      if(response.data.result.distance > 500  && ( ((orderType == "QrDineIn" || orderType == "dineIn" ) &&  venueData?.configurations?.isLocationRestrictionForQrTable == true) ||  (orderType == "QrPickUp" && venueData?.configurations?.isLocationRestrictionForQrVenue == true) )){
        handleOpenVenueFarAwayModal(venueId)
      }
      if(orderType == "QrDineIn"){
        await dispatch(getVenueListWithLocationAndType(location, "isDineIn", true));
      }else if(orderType == "QrPickUp"){
        await dispatch(getVenueListWithLocationAndType(location, "isPickUp", true));
      }
    } catch (error) {
      console.log(error)
    }
  }, [venueId, venues]);

  useEffect(() => {
    fetchMenuData();
  }, []);

 
  return (
    <>
      <VenueHeader />
      {loading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="100vh" />
      ) : (
        <>
          <VenueCover venuesCoverData={venuesCoverData} />
          <VenueProfileMenu
            venueIdToBlock={venueIdToBlock}
            item={menuData}
            venuesCoverData={venuesCoverData}
            modeOfVenue={modeOfVenue}
            venueId={venueId}
          />
        </>
      )}
      <FooterLogin />
      {openCart && cardItems?.data?.items?.length > 0 && (
        <CartItemModal width="md" open={openCart} onClose={handleClose} setOpenCarInformationModal={setOpenCarInformationModal} errorMessage={state?.errorMessage} />
      )}
       {openCarInformationModal && (
        <CarInformationModal width="md" open={openCarInformationModal} onClose={handleCloseCarInformationModal} user={user}/>
      )}
    </>
  );
};

export default VenueProfiles;

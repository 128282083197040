import { Box, Typography, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import VenueProfileMenuCard from "./VenueProfileMenuCard";
import VenueItmeModal from "./venueModal/VenueItmeModal";
import { MUIButtonsPage } from "src/pages/components/mui/MUIButtonsPage";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CartItemModal from "../cartModal/CartItemModal";
import CarInformationModal from "../CarInformation/CarInformationModal";
const VenueProfileMenu = ({ item, venuesCoverData, modeOfVenue, venueId, venueIdToBlock }) => {
  const [orderItem, setOrderItem] = useState(null);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const handleMenuItemClick = (item) => {
    setOpenConfirm(true);
    setOrderItem(item);
    setCart((prevCart) => [...prevCart, item]);
  };
  const [openConfirm, setOpenConfirm] = useState(false);
  const closeModal = () => {
    setOpenConfirm(false);
  };
  const { cardItems } = useSelector((state) => state.cardSlice);
  const handleClose = () => {
    setOpenCart(false);
  };
  const handleCloseCarInformationModal = () => {
    setOpenCarInformationModal(false);
  };
  const [openCart, setOpenCart] = useState(false);
  const [openCarInformationModal, setOpenCarInformationModal] = useState(false);
  const { user } = useSelector((state) => state.login);

  const [activeSection, setActiveSection] = useState(null);
  const handleTitleClick = (name) => {
    const section = document.getElementById(name);
    if (section) {
      const yOffset = -90;
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setActiveSection(name);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = item?.map((menu) => {
        const section = document.getElementById(menu.name);
        return {
          title: menu.name,
          offset: section ? section.getBoundingClientRect().top : 0,
        };
      });
      const inViewSection = sectionOffsets?.find(
        (offset) => offset.offset > 0 && offset.offset < 160
      );
      if (inViewSection) {
        setActiveSection(inViewSection.title);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [item]);

  const maxHeight =
    item.reduce((total, menu) => total + menu.items.length, 0) * 2;

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "none",
            "@media (max-width: 1000px)": {
              display: "block",
            },
          }}
        >
          <MUIButtonsPage TABS={item} />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "45px",
            "@media (max-width: 1000px)": {
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              marginTop: "30px",
              position: "sticky",
              flex: "0 0 22%",
              top: "120px",
              "@media (max-width: 1000px)": {
                display: "none",
              },
              "@media (max-width: 1920px)": {
                maxHeight: maxHeight + "vh",
                marginBottom: "30px",
                minHeight: "100vh",
              },
            }}
          >
            {item.map((menu) => (
              <Box
                key={menu.id}
                id={`${menu.name}-title`}
                className="menu-title"
                onClick={() => handleTitleClick(menu.name)}
              >
                <Typography
                  sx={{
                    backgroundColor:
                      activeSection === menu.name ? "#FCA92E" : "transparent",
                    borderRadius: "5px",
                    paddingTop: "9px",
                    paddingBottom: "9px",
                    paddingLeft: "8px",
                    marginTop: "12px",
                    fontSize: "18px",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  {menu.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              marginBottom: "60px",
              marginTop: "10px",
              flex: "0 0 75.5%",
            }}
          >
            {item.map((menu, index) => (
              <Box
                key={menu.name}
                id={menu.name}
                sx={{ marginBottom: index === item.length - 1 ? "100px" : "" }}
              >
                <Typography
                  sx={{
                    color: "#1E1E1E",
                    marginTop: "30px",
                    marginBottom: "18px",
                    fontSize: "28px",
                    marginLeft: "150px",
                    fontWeight: 800,
                    "@media (max-width: 1440px)": {
                      marginLeft: "0px",
                    },
                    "@media (max-width: 1200px)": {
                      marginTop: "0px",
                    },
                    "@media (max-width: 600px)": {
                      marginTop: "0px",
                    },
                    "@media (max-width: 490px)": {
                      marginBottom: "20px",
                    },
                  }}
                >
                  {menu.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "5px",
                    marginLeft: "150px",
                    "@media (max-width: 1440px)": {
                      marginLeft: "0px",
                    },
                    "@media (max-width: 1200px)": {
                      marginLeft: "0px",
                      marginRight: "0px",
                    },
                    "@media (max-width: 600px)": {
                      marginRight: "0px",
                      marginLeft: "0px",
                      marginTop: "0px",
                      marginBottom: "20px",
                    },
                  }}
                > 
                  {menu?.items
                    ?.filter((item) => item.isAvailable)
                    .map((item) => (
                      <VenueProfileMenuCard
                        key={item.title}
                        item={item}
                        setOrderItem={handleMenuItemClick}
                      />
                    ))}
                </Box>
              </Box>
            ))}
          </Box>

          {openConfirm && (
            <VenueItmeModal
              venueIdToBlock={venueIdToBlock}
              width="md"
              open={openConfirm}
              item={orderItem}
              venuesCoverData={venuesCoverData}
              modeOfVenue={modeOfVenue}
              venueId={venueId}
              onClose={closeModal}
              setOpenConfirm={setOpenConfirm}
            />
          )}

          {cardItems?.data?.items?.length > 0 && (
            <Box
              sx={{
                position: "fixed",
                bottom: 4,
                left: 8,
                right: 8,
                backgroundColor: "#FCA92E",
                borderRadius: "8px",
                padding: "10px",
                textAlign: "center",
                "@media (min-width: 768px)": {
                  display: "none",
                },
              }}
              onClick={() => setOpenCart(!openCart)}
            >
              <Typography variant="h6" color="black">
                View Cart ({cardItems?.data?.items?.length})
              </Typography>
            </Box>
          )}
        </Box>
      </Container>

      {openCart && cardItems?.data?.items?.length > 0 && (
        <CartItemModal
          width="md"
          open={openCart}
          onClose={handleClose}
          setOpenCarInformationModal={setOpenCarInformationModal}
        />
      )}
      {openCarInformationModal && (
        <CarInformationModal
          width="md"
          open={openCarInformationModal}
          onClose={handleCloseCarInformationModal}
          user={user}
        />
      )}
    </>
  );
};

export default VenueProfileMenu;

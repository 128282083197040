import { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import { useCart } from "../CartContext";
import { useState, useCallback, useRef } from "react";
import CartCheckoutTotalSummary from "./CartCheckoutTotalSummary";
import Iconify from "src/components/iconify";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { fetchImage } from "src/utils/fetchImage";
import { PaymentMethods } from "src/sections/payment";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import useSession from "src/utils/useSession";
import { placeOrderFromCart } from "src/redux/slices/orderPlaceSlice";
import { getCartByCustomerId } from "src/redux/slices/cardSlice";
import { fetchTableData } from "src/redux/slices/tableSlice";
import { socket } from "src/App";
import api from "src/utils/axios";
import { calculateAndRoundTax } from "src/utils/tax";
import UpdateUserEmailOrPhone from "../userProfile/updateEmailOrPhone";
import { clearTableData } from "src/redux/slices/tableSlice";
import CartItemModal from "src/pages/Venue/cartModal/CartItemModal";
import axios from "axios";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import { fNumber } from "src/utils/formatNumber";
import { openVenueLocationModal, resetVenueIdtoBlock } from 'src/redux/slices/VenueLocationModalSlice';
import useGeolocation from "src/utils/geolocation";
import { fRound } from "src/utils/formatNumber";

const CartCheckoutSummary = () => {
  const {
    calculateMenuItemTotal,
    resetCart,
    calculateSubTotal,
    selectedTip,
    calculateServiceFee,
    calculateFinalTotal,
  } = useCart();
  const { triggerLocation } = useGeolocation();
  const { isLoading, cardItems } = useSelector((state) => state.cardSlice);
  const navigate = useNavigate();
  const handleMenuPage = () => {
    navigate(`/venue/${cardItems?.data?.venueId}`);
  };

  const dispatch = useDispatch();
  const handleOpenModal = () => dispatch(openVenueLocationModal()); 

  const [imageURLs, setImageURLs] = useState([]);

  const [method, setMethod] = useState("cash");
  const [error, setError] = useState("");
  const [disabled, setIsDisabled] = useState(false);
  const [openCard, setOpenCard] = useState(false);

  const [total, setTotal] = useState(0);
  const [platFormFee, setPlateFormFee] = useState(9.9);
  const [venuePermissions, setVenuePermissions] = useState();
  const [cashAvailable, setCashAvailable] = useState(true);
  const isMounted = useRef(false);

  const taxRate = cardItems?.data?.venueDetail?.configurations?.isPayingTax
    ? method === "cash"
      ? cardItems?.data?.venueDetail?.taxOnCash / 100
      : method === "card"
      ? cardItems?.data?.venueDetail?.taxOnCard / 100
      : 0
    : 0;

  let discount = 0;

  const subTotal = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateSubTotal(cardItems.data.items)
        : 0,
    [cardItems]
  );
  const taxAmount = useMemo(
    () => calculateAndRoundTax(subTotal, taxRate),
    [subTotal, taxRate]
  );
  const serviceFee = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateServiceFee(cardItems, subTotal, discount)
        : 0,
    [cardItems, subTotal, total]
  );
  const t = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? Number(
            calculateFinalTotal(
              cardItems?.data?.items,
              cardItems?.data?.tip > 0 ? cardItems?.data?.tip : 0
            )
          ) + Number(taxAmount)
        : 0,
    [cardItems, taxAmount]
  );

  useEffect(() => {
    let updatedTotal = Number(t);

    if (
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
        "Both" &&
      (method === "cash" || method === "card")
    ) {
      updatedTotal += Number(serviceFee);
    } else if (
      method === "cash" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Cash"
    ) {
      updatedTotal += Number(serviceFee);
    } else if (
      method === "card" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Card"
    ) {
      updatedTotal += Number(serviceFee);
    }

    const addPlateFormFee = updatedTotal + platFormFee
    setTotal(addPlateFormFee);
  }, [
    method,
    serviceFee,
    cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption,
    selectedTip,
    total,
    cardItems,
    t,
    taxAmount,
    calculateFinalTotal,
  ]);

  const handleCheckVenueAndTable = async () => {
    let orderType = localStorage.getItem("mode");
    const checkVenueStatus = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${cardItems?.data?.venueId}`
    );
    if (checkVenueStatus.data.status == "inActive") {
      await selectedVenue(dispatch, orderType, true);
      localStorage.setItem("VenueNotAvailableMessageSeen", false);
      setError("Venue is inActive, You cant place Order");
      const errorMessage = "Venue Not Available";
      navigate(`/venue`, { replace: true, state: { errorMessage } });
      return;
    } else {
      let table = null;
      if (tableData?.id && (orderType == "QrDineIn" || orderType == "dineIn")) {
        table = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/tables/${tableData.id}`
        );
      }
      if ( (table?.data?.isAvailable &&  table) || (orderType != "QrDineIn" && orderType != "dineIn") ) {
        return true;
      } else if(table && !table?.data?.isAvailable) {
        dispatch(clearTableData());
        setError("Table is Not Available");
        return false;
      }else{
        return true;
      }
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      if (cardItems?.length < 1 || cardItems?.data?.items?.length < 0) {
        navigate(`/`);
      }
    } else {
      isMounted.current = true;
    }
  }, [cardItems]);

  useEffect(() => {
    let response;
    const fetchVenues = async () => {
      try {
        response = await api.get(
          `${process.env.REACT_APP_BACKEND_URL}/venues/getVenuePermission/${cardItems?.data?.venueId}`
        );
        setVenuePermissions(response.data);
        let orderType = localStorage.getItem("mode");
        if ( (orderType == "QrPickUp" && !response?.data.configurations?.isCashAvailableOnVenueScan) || (orderType == "pickUp" && !response?.data.configurations?.isCashAvailableOnPickUp )  ) {
          handleCheckVenueAndTable();
          setMethod("card");
          setCashAvailable(false);
          setOpenCard(true);
          
        } else if ( orderType == "QrDineIn" && !response?.data.configurations?.isCashAvailableOnTableScan ) {
          handleCheckVenueAndTable();
          setMethod("card");
          setCashAvailable(false);
          setOpenCard(true);
        }
      } catch (error) {
        console.log("Error Occured", error);
      }
    };
    fetchVenues();
  }, [cardItems]);

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (cardItems && cardItems?.data?.items?.length > 0) {
        const urls = await Promise.all(
          cardItems?.data?.items.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [cardItems]);

  const [openModal, setOpenModal] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  let orderType = localStorage.getItem("mode");
  const sessionInfo = useSession();

  const { tableData } = useSelector((state) => state.tableData);

  const { user, isLoggedIn } = useSelector((state) => state.login);
  const [isLoadings, setIsLoading] = useState(false);

  const placeOrder = useCallback(async () => {
    setError("");
    const checkVenueStatus = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${cardItems?.data?.venueId}`
    );
    const guestUserData = cardItems?.data?.customer;
    const getMode = localStorage.getItem("mode");
    let totalServiceValue = 0;

    if (
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
        "Both" &&
      (method === "cash" || method === "card")
    ) {
      totalServiceValue = Number(serviceFee);
    } else if (
      method === "cash" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Cash"
    ) {
      totalServiceValue = Number(serviceFee);
    } else if (
      method === "card" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Card"
    ) {
      totalServiceValue = Number(serviceFee);
    }
    try {
      setIsLoading(true);
      const location = await triggerLocation(handleOpenModal);
      let response = await dispatch(
        placeOrderFromCart(
          { ...cardItems?.data },
          user?.id,
          fRound(total,2),
          sessionInfo?.sessionId,
          orderType,
          guestUserData,
          tableData?.id,
          method,
          fRound(taxAmount,2),
          fRound(subTotal,2),
          selectedTip === null ? 0 : fRound(selectedTip,2),
          // cardItems?.data?.tip ? cardItems?.data?.tip : 0,
          fRound(totalServiceValue,2),
          location
        )
      );
      if (response?.status === 201) {
        await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
        await dispatch(clearTableData());
        socket.emit("addIncomingOrder", { order: response.data });

        if (response.data.orderType == "QrDineIn") {
          socket.emit("addToTables", {
            table: { ...response.data.tableId, isSuperAdmin: false },
          });
        }

        resetCart();
        navigate("/active");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message);
      await dispatch(clearTableData());
      if ( getMode == "QrDineIn" &&  error?.response?.data?.message == 'Table is Not Available' ) {
        dispatch(resetVenueIdtoBlock())
        localStorage.setItem("mode", "dineIn");
        setOpenCart(true);
      }
      setIsDisabled(true);
      if ( error?.response?.data?.message == "Venue is InActive, You cant place Order") {
        await selectedVenue(dispatch, orderType, true);
        localStorage.setItem("VenueNotAvailableMessageSeen", false);
        const errorMessage = "Venue Not Available";
        navigate(`/venue`, { replace: true, state: { errorMessage } });
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    cardItems,
    tableData,
    total,
    selectedTip,
    serviceFee,
    taxAmount,
    subTotal,
  ]);
  const handleClose = () => {
    setOpenCart(false);
  };

  return (
    <>
      {openCart && (
        <CartItemModal
          open={openCart}
          onClose={handleClose}
          errorMessage={error}
        />
      )}
      {isLoading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="70vh" />
      ) : (
        <Box
          mb={5}
          sx={{
            marginLeft: "35px",
            marginRight: "35px",
            "@media (max-width: 1025px)": {
              marginLeft: "0px",
              marginRight: "0px",
            },
          }}
        >
          <Container maxWidth="xl">
            {!user?.isPhoneVerified && isLoggedIn && (
              <Card
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  borderBottom: "none",
                  borderRadius: "20px",
                  marginTop: "140px",
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Grid>
                      <Box>
                        <Typography variant="h3">
                          Personal Information
                        </Typography>

                        <Typography variant="body1" color="error">
                          Your phone is not verified, please verify before
                          checkout.
                        </Typography>
                        <UpdateUserEmailOrPhone context="CartCheckoutSummary" />
                      </Box>
                    </Grid>
                  </Stack>
                </CardContent>
              </Card>
            )}

            <Grid
              container
              spacing={3}
              sx={{
                marginTop: "100px",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "700",
                    marginLeft: "14px",
                    marginTop: "0px",
                    fontSize: "25px",
                    marginBottom: "10px",
                  }}
                >
                  Order Summary
                </Typography>
                <Box
                  mb={3}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.10)",
                    borderRadius: "20px",
                  }}
                >
                  {cardItems?.data?.items &&
                  cardItems?.data?.items?.length > 0 ? (
                    <>
                      {cardItems.data?.items?.map((cartItem, index) => (
                        <>
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: "10px 0",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                gap: "30px",
                                "@media (max-width: 600px)": {
                                  gap: "10px",
                                  marginLeft: "0px",
                                },
                              }}
                            >
                              {cartItem.qty > 0 && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    gap: "20px",
                                    color: "#F08203",
                                    marginLeft: "20px",
                                    "@media (max-width: 600px)": {
                                      gap: "10px",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {cartItem.qty}
                                  </Typography>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    x
                                  </Typography>
                                </Stack>
                              )}
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    "@media (max-width: 600px)": {
                                      flexDirection: "column",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      "@media (max-width: 600px)": {
                                        marginLeft: "5px",
                                      },
                                    }}
                                  >
                                    {cartItem.name}
                                  </Typography>
                                  {cartItem?.selectedVariant && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                          color: "#666",

                                          "@media (max-width: 600px)": {
                                            marginLeft: "5px",
                                          },
                                        }}
                                      >
                                        Variant:
                                      </Typography>
                                      <Typography sx={{ ml: 1, fontSize: 12 }}>
                                        {cartItem?.selectedVariant?.name}
                                      </Typography>
                                    </Box>
                                  )}

                                  {cartItem?.groups &&
                                    cartItem?.groups?.length > 0 && (
                                      <>
                                        {cartItem?.groups?.map((sauce) => (
                                          <Typography
                                            key={index}
                                            variant="caption"
                                            sx={{
                                              color: "#666",
                                              display: "flex",
                                              flexWrap: "wrap",
                                              flexDirection: "row",

                                              gap: "2px",
                                              fontWeight: "bold",
                                              "@media (max-width: 600px)": {
                                                fontSize: "10px",
                                                marginLeft: "5px",
                                                gap: "1px",
                                              },
                                            }}
                                          >
                                            {sauce?.name} :
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color: "#666",
                                                display: "flex",
                                                gap: "2px",
                                                flexWrap: "wrap",
                                                "@media (max-width: 600px)": {
                                                  fontSize: "10px",
                                                  gap: "1px",
                                                },
                                              }}
                                            >
                                              {sauce?.items?.map(
                                                (sauceItem, sauceIndex) => (
                                                  <span key={sauceIndex}>
                                                    {sauceItem?.item}
                                                    {` (Rs. ${
                                                      fNumber(sauceItem.price *
                                                      cartItem.qty)
                                                    })`}
                                                    {sauceIndex !==
                                                      sauce?.items?.length -
                                                        1 && ", "}
                                                  </span>
                                                )
                                              )}
                                            </Typography>
                                          </Typography>
                                        ))}
                                      </>
                                    )}
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "red",
                                      display: "flex",
                                      marginLeft: "10px",
                                      gap: "2px",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    <span>{cartItem?.notes}</span>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#F08203",
                                  marginTop: "4px",
                                  fontSize: "16px",

                                  marginRight: "15px",
                                  "@media (max-width: 1440px)": {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {calculateMenuItemTotal(cartItem)}
                              </Typography>
                            </Stack>
                          </Box>

                          <Divider
                            sx={{
                              marginRight: "13px",
                              marginLeft: "8px",
                              marginTop: "10px",
                              color: "black",
                            }}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <Box>
                      <Typography
                        sx={{
                          height: "20vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "25px",
                        }}
                      >
                        Cart is Empty
                      </Typography>
                    </Box>
                  )}
                  <>
                    {cardItems?.data?.items &&
                      cardItems?.data?.items?.length > 0 && (
                        <Link
                          href="#"
                          underline="none"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            marginRight: "20px",
                          }}
                        >
                          <Button
                            onClick={handleMenuPage}
                            size="small"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            sx={{
                              my: 1.5,
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            Add More items
                          </Button>
                        </Link>
                      )}
                  </>
                </Box>

                {orderType === "QrPickUp" && (
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.10)",
                      borderBottom: "none",
                      borderRadius: "20px",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: "700",
                          marginLeft: "25px",
                          fontSize: "25px",
                          padding: "10px",
                        }}
                      >
                        Car Information
                      </Typography>
                      <Divider />
                    </Box>
                    {cardItems?.data?.customer && (
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              Car Number
                            </Typography>
                            {cardItems?.data?.items &&
                              cardItems?.data?.items?.length > 0 && (
                                <Typography variant="subtitle2">
                                  {cardItems?.data?.customer?.carNumber}
                                </Typography>
                              )}
                          </Stack>

                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              Car Color
                            </Typography>
                            {cardItems?.data?.items &&
                              cardItems?.data?.items?.length > 0 && (
                                <Typography variant="subtitle2">
                                  {cardItems?.data?.customer?.carColor}
                                </Typography>
                              )}
                          </Stack>

                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              Notes
                            </Typography>
                            {cardItems?.data?.items &&
                              cardItems?.data?.items?.length > 0 && (
                                <Typography variant="subtitle2">
                                  {cardItems?.data?.customer?.notes}
                                </Typography>
                              )}
                          </Stack>
                        </Stack>
                      </CardContent>
                    )}
                  </Card>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <CartCheckoutTotalSummary
                  openCard={openCard}
                  setOpenCard={setOpenCard}
                  method={method}
                  error={error}
                  setError={setError}
                  setMethod={setMethod}
                  placeOrder={placeOrder}
                  cashAvailable={cashAvailable}
                  serviceFee={serviceFee}
                  taxAmount={taxAmount}
                  total={total}
                  isLoading={isLoadings}
                  disabled={disabled}
                  setIsDisabled={setIsDisabled}
                  setOpenCart={setOpenCart}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};
export default CartCheckoutSummary;

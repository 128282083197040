import { useEffect, useCallback } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCartByCustomerId } from "src/redux/slices/cardSlice";
import { getActiveOrders } from "src/redux/slices/orderPlaceSlice";
import { useTheme } from "@mui/material/styles";
import Iconify from "src/components/iconify/Iconify";
import { useLocation } from "react-router";
import useSession from "src/utils/useSession";
import { selectedVenue } from "src/utils/venueSelectionUtils";

const HeaderCart = ({ openCart, setOpenCart }) => {
  const navigate = useNavigate();
  const gotoAvtiveOrder = () => {
    navigate(`/active`);
  };
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.login);
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { order } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.login);

  const sessionInfo = useSession();

  const getCartItem = useCallback(async () => {
    try {
      const response = await dispatch(
        getCartByCustomerId(sessionInfo?.sessionId)
      );
      if (response?.response?.data?.message == "Venue is InActive") {
        let orderType = localStorage.getItem("mode");
        await selectedVenue(dispatch, orderType, true);
        localStorage.setItem("VenueNotAvailableMessageSeen", false);
        const errorMessage = "Venue Not Available";
        navigate(`/venue`, { replace: true, state: { errorMessage } });
        return;
      }
      await dispatch(getActiveOrders(sessionInfo?.sessionId,user?.id));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [cardItems]);

  const filteredOrders =
    order &&
    order.length > 0 &&
    order?.filter((item) =>
      ["pending", "processing", "finished", "completed"].includes(item.state)
    );
  const hasFilteredOrders = filteredOrders && filteredOrders.length > 0;

  useEffect(() => {
    getCartItem();
  }, []);

  return (
    <>
      {location?.pathname !== "/auth/login" &&
        location?.pathname !== "/auth/register" &&
        location?.pathname !== "/auth/reset-password" &&
        location?.pathname !== "/auth/verify" && (
          <>
            {isMediumScreen ? (
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {hasFilteredOrders && (
                  <Button
                    sx={{
                      color: "black",
                      display: "flex",
                      // gap: "5px",
                      height: "42px",
                    }}
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    onClick={gotoAvtiveOrder}
                  >
                    Active order
                  </Button>
                )}
                <Button
                  sx={{
                    color: "black",
                    display: "flex",
                    gap: "5px",
                    height: "42px",
                  }}
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={() => setOpenCart(!openCart)}
                > 
                  <Iconify icon="ph:bag" />
                  Cart -
                  {cardItems?.data?.items?.length > 0
                    ? cardItems?.data?.items?.length
                    : 0}
                </Button>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {hasFilteredOrders && (
                    <Button
                      sx={{
                        color: "#FCA92E",
                        minWidth: "40px",
                        padding: "0",
                        display: "flex",
                        marginTop: "4px",
                        backgroundColor: "transparent",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      variant="contained"
                      target="_blank"
                      rel="noopener"
                      onClick={gotoAvtiveOrder}
                    >
                      <img
                        src="/assets/header-tabs/activeOrder.svg"
                        alt="Cart icon"
                        style={{ height: "22px", width: "22px" }}
                      />
                    </Button>
                  )}
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      position: "relative",
                      marginTop: "4px",
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Button
                        sx={{
                          color: "#FCA92E",
                          minWidth: "40px",
                          padding: "0",
                          display: "flex",
                          backgroundColor: "transparent",
                          "&:hover": {
                            background: "none",
                          },
                        }}
                        variant="contained"
                        target="_blank"
                        rel="noopener"
                        onClick={() => setOpenCart(!openCart)}
                      >
                        <img
                          src="/assets/header-tabs/cart.svg"
                          alt="Cart icon"
                          style={{ height: "22px", width: "22px" }}
                        />
                      </Button>
                      {cardItems?.data?.items?.length > 0 && (
                        <Typography
                          component="div"
                          sx={{
                            position: "absolute",
                            bottom: "-4px",
                            right: isLoggedIn ? "2px" : "7px",
                            backgroundColor: "#FFA500",
                            borderRadius: "50%",
                            width: "14px ",
                            height: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "11px",
                          }}
                        >
                          {cardItems?.data?.items?.length > 0
                            ? cardItems?.data?.items?.length
                            : 0}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
    </>
  );
};

export default HeaderCart;

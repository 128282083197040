import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmDialog from "src/components/confirm-dialog";
import Iconify from "src/components/iconify/Iconify";
import VenueItmeModal from "../Venue Profiles/venueModal/VenueItmeModal";
import VenueProfileMenuCard from "../Venue Profiles/VenueProfileMenuCard";
import api from "src/utils/axios";
import { fetchImage } from "src/utils/fetchImage";
import { useNavigate } from "react-router";

export const UpsellingDialog = ({ open, onClose, items, setOpenCarInformationModal }) => {
    const navigate = useNavigate();
    const [isVenueItemModalOpen, setIsVenueItemModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [venuesCoverDate, setVenuesCoverDate] = useState({});
    const { cardSlice, venueLocationModal, venues } = useSelector((state) => state) ?? {}
    const { venueIdToBlock } = venueLocationModal ?? {}
    const { cardItems } = cardSlice ?? {};
    const { data } = cardItems ?? {};
    const { venueId } = data ?? {};
    let orderType = localStorage.getItem("mode");
    const { isLoggedIn } = useSelector((state) => state.login);


    const closeVenueItemModal = () => {
        setIsVenueItemModalOpen(false)
    }

    const checkLogin=()=>{
        if (isLoggedIn) {
            navigate(`/checkout`);
        }
        else {
            navigate(`/auth/login?type=${orderType}`)
        }
    }

    const handleSkip = () => {
        if (orderType == "pickUp") {
            checkLogin();
        } else if (orderType == "QrPickUp") {
            setOpenCarInformationModal(true);
        } else {
            checkLogin();
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const venue = venues?.venues?.find((venue) => venue.id === venueId);
                const venueResponse = await api.get(
                    `${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${venueId}`
                );
                const res = await fetchImage(venueResponse?.data?.photoURL);

                const response = {
                    detail: venueResponse?.data,
                    image: res,
                    distance: venue?.distance,
                }
                setVenuesCoverDate(response);

            } catch (error) {
                console.log('error while fetching venues', error?.message)
            }
        }
        )()
    }, [venueId])

    return (
        <>
            <ConfirmDialog open={open} width={"md"} onClose={onClose}>
                {/* Dialog Header */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        p: 2
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "700",
                            fontSize: "25px",
                            m: 0,
                            p: 0,
                        }}
                    >
                        Upselling Items

                    </Typography>

                    <Box sx={{ m: 0, p: 0 }}>
                        <IconButton
                            aria-label="close"
                            onClick={() => onClose(!open)}
                            sx={{
                                position: {
                                    xs: "static",
                                },
                                top: 20,
                                right: 8,
                            }}
                        >
                            <Iconify
                                sx={{
                                    height: 25,
                                    width: 25,
                                }}
                                icon="mdi:close"
                                color="#fca92e"
                            />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                {/* Content */}

                <Box sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                        {items?.map((item) => (
                            <>
                                <Grid item xs={12} md={6}>
                                    <VenueProfileMenuCard
                                        item={item}
                                        width={'100%'}
                                        setOrderItem={() => {
                                            setIsVenueItemModalOpen(true)
                                            setSelectedItem(item)
                                        }}
                                    />
                                </Grid>
                            </>
                        ))}
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 4 }}>
                        <Button variant="contained" onClick={handleSkip}>
                            Skip, continue to checkout
                        </Button>
                    </Box>
                </Box>
            </ConfirmDialog>

            <VenueItmeModal
                venueIdToBlock={venueIdToBlock}
                width="md"
                open={isVenueItemModalOpen}
                item={selectedItem}
                venuesCoverData={venuesCoverDate}
                modeOfVenue={{ isPickUp: cardSlice?.isPickUp, isDineIn: cardSlice?.isDineIn }}
                venueId={venueId}
                onClose={closeVenueItemModal}
                setOpenConfirm={setIsVenueItemModalOpen}
            />
        </>
    )
}
